<template>
    <div style="margin: 100px 0;">
        <sui-container>
            <sui-card-group :items-per-row="1">
                <sui-card>
                    <sui-card-content>
                        <sui-card-header>{{ loggedUser.supervisor.firstname }} {{ loggedUser.supervisor.lastname }}
                        </sui-card-header>
                        <sui-card-meta>{{ loggedUser.supervisor.region }}</sui-card-meta>
                        <sui-card-description>
                            <p>{{ loggedUser.supervisor.description }}</p>
                            <sui-icon size="small" name="mail" /><a class="supervisor-link" :href="`mailto:${loggedUser.supervisor.email}`">{{ loggedUser.supervisor.email }}</a><br>
                            <sui-icon size="small" name="phone" /><a class="supervisor-link" :href="`tel:${loggedUser.supervisor.phone}`">{{ loggedUser.supervisor.phone }}</a><br>
                        </sui-card-description>
                    </sui-card-content>
                </sui-card>
            </sui-card-group>
        </sui-container>
    </div>
</template>

<script>
export default {
    name: "Supervisor",
    computed: {
        loggedUser() {
            return this.$store.state.users.loggedUser
        }
    }
};
</script>

<style scoped>
.supervisor-link {
    color: #000000AD;
}
.supervisor-link:hover {
    color: #121212;
}
</style>
